<template>
  <div class="form-videomeeting">
    <conferenceTable ref="conferenceTable"></conferenceTable>
    <!-- <conferenceRoom v-if="conferenceStatus"></conferenceRoom> -->
  </div>
</template>

<script>
import { core } from '../../config/pluginInit'
import conferenceTable from './FormVideomeeting/conferenceTable.vue';
// import conferenceRoom from './FormVideomeeting/conferenceRoom.vue';
export default {
  name: "FormVideomeeting",
  components: {
    conferenceTable,
    // conferenceRoom,
  },
  data() {
    return {
      // conferenceStatus: false, // 会议室状态
    }
  },
  created() {
  },
  mounted() {
    core.index()
  },
  methods: {
    init() {
      this.$refs.conferenceTable.init();
    },
    // 进入会议室
    // entranceConferenc() {
    //   this.conferenceStatus = true;
    // },
  }
}
</script>

<style lang="less" scoped>
  .form-videomeeting {
    height: 80vh;
    padding-bottom: 20px;
    margin-block: 20px;
    margin-top: 15px;
  }
</style>

