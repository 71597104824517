var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-conference"},[_c('el-dialog',{attrs:{"title":_vm.info.type == 'add' ? '新建会议' : '修改会议',"center":"","show-close":false,"visible":_vm.dialogVisible,"width":"460px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"row-box"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* ")]),_vm._v(" 会议标题 ")]),_c('el-input',{attrs:{"placeholder":"请输入会议标题"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"row-box"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* ")]),_vm._v(" 选择日期 ")]),_c('el-date-picker',{attrs:{"clearable":false,"picker-options":_vm.$set_PickerOption1(),"type":"date","placeholder":"选择日期"},model:{value:(_vm.dateVal),callback:function ($$v) {_vm.dateVal=$$v},expression:"dateVal"}})],1),_c('div',{staticClass:"row-box"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* ")]),_vm._v(" 选择时间 ")]),_c('div',{staticClass:"time-box"},[_c('el-time-select',{attrs:{"placeholder":"开始时间","clearable":false,"picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30',
            maxTime: _vm.endTime,
          }},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('el-time-select',{attrs:{"placeholder":"结束时间","clearable":false,"picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30',
            minTime: _vm.startTime,
          }},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)]),_c('div',{staticClass:"row-box"},[_c('p',{staticClass:"label"},[_vm._v("会议类型")]),_c('div',{staticClass:"type-box"},[_vm._v(_vm._s(_vm.form.typeVal))])]),_c('div',{staticClass:"row-box"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* ")]),_vm._v(" 会议目标 ")]),_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6 },"placeholder":"请输入会议目标..."},model:{value:(_vm.form.target),callback:function ($$v) {_vm.$set(_vm.form, "target", $$v)},expression:"form.target"}})],1),_c('div',{staticClass:"btns-box"},[_c('button',{staticClass:"btn",on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('button',{staticClass:"btn2",on:{"click":_vm.submit}},[_vm._v("保 存")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }