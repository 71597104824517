<template>
  <div class="conference-table">
    <p class="all-crumbs">智能工具 > iLaaS Room</p>
    <button class="btn" @click="add">新建会议</button>
    <div class="content-box">
      <div class="content-left">
        <p class="label">待进行</p>
        <div class="cards-box">
          <div class="card" v-for="item in myList" :key="item.id">
            <div class="title">
              <p>{{item.name}}</p>
            </div>
            <div class="date-box">
              <div class="time-box">
                {{item.betweenTime}}
              </div>
              <span class="val-p" v-if="$getDate(1,item.startTime) == $getDate(1)">今天</span>
              <span class="val-p val-p2" v-else>{{item.startTime.split(' ')[0]}}</span>
            </div>
            <div :class="`card-fool ${item.status === 0 ? 'card-fool2':''}`">
              <p>
                <span class="dot"></span>
                {{item.status === 1 ? '进行中':'待进行'}}
              </p>
              <button class="btn1" @click="invite(item)">邀请</button>
              <button class="btn2" @click="entrance(item)">进入会议</button>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <p class="label">历史会议</p>
        <div class="cards-box">
          <div class="card" v-for="item2 in myList2" :key="item2.id" @click="lookDetails(item2)">
            <div class="title">
              <p>{{item2.name}}</p>
              <div class="title-right">
                <span class="dot"></span>
                <span class="val-span">已结束</span>
              </div>
            </div>
            <div class="date-box date-box2">
              <div class="time-box">
                {{item2.date}}/{{item2.betweenTime}}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{item2.creatorName}}&nbsp;&nbsp;发起的会议
                <div class="icon-box">
                  <img
                    src="@/assets/images/index/icon-3.png"
                    @mouseover="mouseOver(item2.id)"
                    @mouseleave="mouseLeave"
                  alt="">
                  <div class="icon-pop" v-if="showPop == item2.id">
                    <!-- <div class="row-box">
                      <img src="@/assets/images/index/icon-4.png" alt="">
                      <span>120分钟</span>
                    </div> -->
                    <div class="row-box">
                      <img src="@/assets/images/index/icon-5.png" alt="">
                      <span>{{item2.creatorName}}（主持人）</span>
                    </div>
                    <div class="row-box">
                      <img src="@/assets/images/index/icon-6.png" alt="">
                      <span>参会者（{{item2.participants.length}}人）</span>
                    </div>
                    <div class="names-box">
                      <p v-for="item3 in item2.participants" :key="item3">{{item3}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-icon" @mouseover="mouseOver2(item2.id)" @mouseleave="mouseLeave2">
                <img
                  class="right-icon"
                  src="@/assets/images/index/icon-2.png"
                  alt="">
                <div class="operate-pop" v-if="showPop2 == item2.id">
                  <div class="max-box">
                    <div class="row-box" @click="download(item2)">
                      <img src="@/assets/images/index/icon-7.png" alt="">
                      <span>导出会议纪要</span>
                    </div>
                    <div class="row-box" @click="clickDel(item2)">
                      <img src="@/assets/images/index/icon-8.png" alt="">
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <addConference v-if="showDialog" :info="dialigInfo"></addConference>
    <setRoomLink v-if="roomLinDialog" :info="roomLinInfo"></setRoomLink>
    <conferenceDetails ref="conferenceDetails" v-if="detailsStatus" :info="detailsInfo"></conferenceDetails>
    <el-dialog
      title="使用提示"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="460px"
      :before-close="handleClose"
    >
      <!-- <p @click="linkUrl" class="link-p">{{info.path}}</p> -->
      <p style="text-align: center;">iLaaS Room 暂未对体验会员开放，如需使用请联系我们！</p>
      <img
        style="width: 400px;"
        src="@/assets/images/kf-img.png"
        alt="">
      <div class="btns-box">
        <button class="btn" @click="dialogVisible = false">关 闭</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import conferenceDetails from './conferenceDetails.vue'
import addConference from '@/views/Dialog/addConference'
import setRoomLink from '@/views/Dialog/setRoomLink'
import { getConference,getHistoryConference, getIssueExport, conference, getConferenceDetail } from '@/FackApi/api/iLaaSRoom';
import { Loading } from "element-ui";
export default {
  name: "conferenceTable",
  components: {
    conferenceDetails,
    addConference,
    setRoomLink,
  },
  data() {
    return {
      showPop: '',
      showPop2: '',
      showDialog: false,
      dialigInfo: {
        type: 'add',
      },
      currentPage: 1,
      interPage: 200,
      dataSize: 0,
      myList: [], // 未结束会议列表
      currentPage2: 1,
      interPage2: 200,
      dataSize2: 0,
      myList2: [], // 历史会议列表
      roomLinDialog: false,
      roomLinInfo: null, // 会议室链接数据
      detailsStatus: false, // 会议详情
      detailsInfo: null, // 点击详情数据

      dialogVisible: false,
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      this.myList = [];
      this.myList2 = [];
      this.getConferenceInfo();
      this.getHistoryConferenceInfo();
    },
    // 获取会议
    async getConferenceInfo() {
      let params = `${this.currentPage}/${this.interPage}`;
      let res = await getConference(params);
      // console.log(res);
      if (res.code == 0) {
        let data = res.items;
        data.map(item => {
          let startArr = item.startTime.split(' ');
          let endArr = item.endTime.split(' ');
          item.date = startArr[0];
          item.betweenTime = `${startArr[1].slice(0,5)}-${endArr[1].slice(0,5)}`;
        })
        this.myList = this.myList.concat(res.items);
        this.dataSize = res.totalCount;
      }
    },
    // 获取历史会议
    async getHistoryConferenceInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = `${this.currentPage2}/${this.interPage2}`;
      let res = await getHistoryConference(params);
      // console.log(res);
      loadingInstance.close();
      if (res.code == 0) {
        let data = res.items;
        data.map(item => {
          let startArr = item.startTime.split(' ');
          let endArr = item.endTime.split(' ');
          item.date = startArr[0];
          item.betweenTime = `${startArr[1].slice(0,5)}-${endArr[1].slice(0,5)}`;
        })
        this.myList2 = this.myList2.concat(res.items);
        // console.log(this.myList2);
        this.dataSize2 = res.totalCount;
      }
    },
    // 会议邀请
    invite(row) {
      console.log(row);
      // 参会者端链接地址
      let path = this.$participantLink(row.roomNumber);
      this.roomLinInfo = {path: path};
      this.roomLinDialog = true;
    },
    // 进入会议
    async entrance(row) {
      // console.log(row);return
      let res = await getConferenceDetail(row.roomNumber);
      if (res.code == 0) {
        if (res.item.status == 2) {
          this.init();
          this.$message.warning("会议已经结束！");
          return
        }
        this.$store.dispatch('index/setConferenceInfo', row);
      }
    },
    // 新增会议
    add() {
      // this.showDialog = true;
      this.dialogVisible = true;
    },
    // 鼠标进入会议图标
    mouseOver(id) {
      this.showPop = id;
    },
    // 鼠标离开会议图标
    mouseLeave() {
      this.showPop = '';
    },
    // 鼠标进入会议图标
    mouseOver2(id) {
      this.showPop2 = id;
    },
    // 鼠标离开会议图标
    mouseLeave2() {
      this.showPop2 = '';
    },
    // 导出会议纪要
    async download(row) {
      // console.log(row);return
      event.stopPropagation();
      let res3 = await getIssueExport(row.id);
      let loadingInstance = Loading.service({ fullscreen: true });
      loadingInstance.close();
      let data = res3;
      const fileName = `${row.name}.pdf`;
      const blob =
        data instanceof Blob
          ? data
          : new Blob([data], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            });
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    // 点击删除
    clickDel(row) {
      event.stopPropagation();
      this.$confirm(`是否确认删除会议【 ${row.name} 】？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delConference(row);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 删除会议
    async delConference(row) {
      let res = await conference(row.id);
      if (res.code == 0) {
        this.init();
      }
    },
    // 查看会议详情
    lookDetails(item) {
      this.detailsInfo = item;
      this.detailsStatus = true;
      this.$nextTick(()=>{
        this.$refs.conferenceDetails.init(item);
      })
    },

    handleClose() {},
  },
}
</script>

<style lang="less" scoped>
  .conference-table {
    position: relative;
    height: 100%;
    background-color: #fff;
    margin: 0 15px;
    padding: 20px 40px;
    font-size: 14px;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px rgba(152,172,190,0.2);
    .btn {
      width: 120px;
      height: 40px;
      color: #fff;
      border-radius: 8px;
      background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
      border: none;
      margin-bottom: 20px;
    }
    .btn:hover {
      color: #fff;
    }
    .content-box {
      display: flex;
      justify-content: space-between;
      height: 90%;
      .content-left,
      .content-right {
        width: 45%;
        .label {
          color: #333;
          padding-bottom: 12px;
          border-bottom: 1px solid #DEE2E6;
        }
        .cards-box {
          height:80%;
          overflow-y: auto;
          margin: 16px 0;
          .card {
            // height: 150px;
            border-radius: 18px;
            border: 1px solid #DEE2E6;
            margin-bottom: 16px;
            padding: 20px;
            z-index: initial;
            .title {
              display: flex;
              align-items: center;
              p {
                width: 85%;
                color: #333;
                font-weight: 600;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .title-right {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .dot {
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: #909399;
                  margin-right: 10px;
                }
                .val-span {
                  font-weight: bold;
                  color: #909399;
                }
              }
            }
            .date-box {
              display: flex;
              align-items: center;
              padding-bottom: 10px;
              border-bottom: 1px solid #DEE2E6;
              margin-bottom: 16px;
              .time-box {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #909399;
                .icon-box {
                  position: relative;
                  margin-left: 10px;
                  cursor:pointer;
                  img {
                    width: 14px;
                    height: 14px;
                    vertical-align: middle;
                  }
                  .icon-pop {
                    position: absolute;
                    top: 20px;
                    left: 50%;
                    transform: translate(-54%, 0);
                    width: 150px;
                    min-height: 180px;
                    // max-height: 340px;
                    border-radius: 16px;
                    overflow-y: auto;
                    border: 1px solid #D7DBDA;
                    background-color: #fff;
                    padding: 14px;
                    z-index: 2;
                    .row-box {
                      margin-bottom: 6px;
                      img {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                      }
                      span {
                        font-size: 12px;
                        color: #333;
                      }
                    }
                    .names-box {
                      display: flex;
                      flex-wrap: wrap;
                      p {
                        width: 50%;
                        text-align: center;
                        font-size: 12px;
                        color: #909399;
                        margin-bottom: 6px;
                      }
                    }
                  }
                }
              }
              .val-p {
                font-size: 12px;
                margin-left: 16px;
                color: #6474C7;
              }
              .val-p2 {
                color: #909399;
              }
              .right-icon {
                position: relative;
                width: 25px;
                height: 25px;
                cursor:pointer;
                img {
                  width: 100%;
                  height: 100%;
                }
                .operate-pop {
                  position: absolute;
                  top: 10px;
                  right: 0;
                  z-index: 2;
                  padding-top: 20px;
                  .max-box {
                    width: 150px;
                    height: 80px;
                    border-radius: 10px;
                    border: 1px solid #D7DBDA;
                    background-color: #fff;
                    padding: 14px;
                    .row-box {
                      img {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                      }
                      span {
                        font-size: 12px;
                        color: #333;
                      }
                    }
                  }
                }
              }
            }
            .date-box2 {
              display: flex;
              justify-content: space-between;
              margin: 10px 0 0 0;
              padding-bottom: 0;
              border: none;
            }
            .card-fool {
              // height: 40px;
              display: flex;
              align-items: flex-end;
              p {
                flex: 1;
                display: flex;
                align-items: center;
                color: #6474C7;
                font-weight: 600;
                .dot {
                  display: block;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background: #6474C7;
                  margin-right: 10px;
                }
              }
              .btn1 {
                width: 70px;
                height: 34px;
                color: #6474C7;
                border-radius: 8px;
                background-color: #fff;
                border: 1px solid #6474C7;
                margin-right: 10px;
              }
              .btn2 {
                width: 100px;
                height: 34px;
                color: #fff;
                background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
                border-radius: 8px;
                border: none;
              }
            }
            .card-fool2 {
              p {
                color: #FCC647;
                .dot {
                  background: #FCC647;
                }
              }
            }
          }
        }
        // .add-btn {
        //   width: 100%;
        //   line-height: 40px;
        //   background: #F8F9FA;
        //   border-radius: 18px;
        //   border: 1px solid #DEE2E6;
        //   img {
        //     width: 20px;
        //     height: 20px;
        //     margin-right: 10px;
        //     margin-bottom: 4px;
        //   }
        // }
      }
    }
    .all-crumbs {
      margin-bottom: 20px !important;
    }
  }

  .btns-box {
    display: flex;
    justify-content: center;
  }
</style>

