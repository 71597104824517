<template>
  <div class="conference-details">
    <!-- <p class="all-crumbs">项目管理 > 我的项目</p> -->
    <div class="btns-box">
      <button class="back-btn" @click="goBack">返回</button>
      <button class="back-btn" @click="edit">保存修改</button>
    </div>
    <div class="center-box">
      <div class="left-side">
          <p class="title">议程</p>
          <div class="left-cards-box" v-if="agendaInfo.status">
            <p v-for="(item3,index3) in discussionList" :key="item3.uid" :title="`${index3 + 1} ${item3.title}`">
              <template v-if="!item3.submit">
                {{index3 + 1}}.
                {{item3.title}}
              </template>
            </p>
          </div>
        </div>
        <div class="center">
          <div class="center-cards-box" v-if="agendaInfo.status">
            <div
              class="card"
              v-for="(item2, index2) in discussionList"
              :key="item2.user"
            >
              <div class="head-box">
                <p>
                  <span v-if="!item2.issuesubmit">
                    {{ index2 + 1 }}.
                  </span>
                  <el-input v-model="item2.title" placeholder="请输入议题标题..."></el-input>
                  <!-- <img class="del-icon" src="@/assets/images/iLaaSRoom/icon2.png" @click="delTopic(item2)" alt=""> -->
                </p>
              </div>
              <div class="centent-box">
                <p class="label">议题内容：</p>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 1 }"
                  placeholder="请输入议题内容..."
                  v-model="item2.content"
                >
                </el-input>
              </div>
              <div class="centent-box">
                <p class="label">议题总结：</p>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 1 }"
                  placeholder="请输入议题总结..."
                  v-model="item2.summary"
                >
                </el-input>
              </div>
              <div class="foot">
                <p v-if="item2.creatorName">
                  由用户&nbsp;
                  <span>
                    {{ item2.creatorName }} 
                  </span>
                  &nbsp;创建</p>
              </div>
            </div>
          </div>
          <!-- <button class="btn" @click="addThesis">
            <img src="@/assets/images/index/icon-1.png" alt="" />
            <span>添加议题</span>
          </button> -->
        </div>
        <div class="right-box" v-if="agendaInfo.status">
          <div class="box-1">
            <p class="title">参会信息</p>
            <div class="row-box">
              <p>主持人：{{agendaInfo.creatorName}}</p>
            </div>
            <div class="row-box">
              <p>参会者：{{agendaInfo.participants.length}}人</p>
            </div>
            <div class="row-box row-box2">
              <span v-for="item4 in agendaInfo.participants" :key="item4">{{item4}}&nbsp;&nbsp;</span>
            </div>
          </div>
          <div class="box-1 box-2">
            <p class="title">录制文件</p>
            <div class="file-box">
              <div class="card" v-for="(item3,index3) in agendaInfo.recordFiles" :key="item3.id">
                <img class="img1" v-if="item3.fileType == 'mp3'" src="@/assets/images/iLaaSRoom/yp-icon.png" alt="">
                <img class="img1" v-else src="@/assets/images/iLaaSRoom/sp-icon.png" alt="">
                <div class="right-box">
                  <p class="file-name">{{item3.fileType}}_{{index3+1}}</p>
                  <p class="file-name date" :title="item3.createTime">{{item3.createTime}}</p>
                </div>
                <p class="btn-p" @click="download(item3,index3)">下载</p>
              </div>
            </div>
          </div>
          <div class="box-1 box-3">
            <p class="title">文件协同</p>
            <div class="file-box2">
              <div class="card2" v-for="item4 in synergyFileList" :key="item4.id">
                <img class="img1" src="@/assets/images/iLaaSRoom/word.png" alt="">
                <div class="right-box">
                  <p class="name">{{item4.fileName}}</p>
                  <p class="btn-p" @click="download2(item4)">下载</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getHistoryDetails, editDiscussion, deleteDiscussion } from '@/FackApi/api/iLaaSRoom';
import { getPolicyToken, getFileUrl } from "@/FackApi/api/index";
var OSS = require('ali-oss');
export default {
  name: "conferenceDetails",
  props: ["info"],
  components: {
  },
  data() {
    return {
      myInfo: null,
      agendaInfo: {
        status: false,
        issues: [],
      },
      transcribeList: [], // 录制的文件
      synergyFileList: [], // 协同的文件
      discussionList: [], // 议题数组
      ossInfo: null, 
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    init(info) {
      this.myInfo = info;
      this.$nextTick(() => {
        this.getHistoryDetailsInfo();
      })
    },
    // 获取会议详情
    async getHistoryDetailsInfo() {
      console.log(this.myInfo);
      let res = await getHistoryDetails(this.myInfo.id);
      // console.log(res);
      if (res.code == 0) {
        this.agendaInfo = res.item;
        this.agendaInfo.status = true;
        this.discussionList = res.item.issues;
        console.log(this.discussionList);
        this.synergyFileList = res.item.files;
        this.transcribeList = res.item.recordFiles;
        this.transcribeList.forEach(item => {
          item.time = item.createTime.split(' ')[1];
          item.dwUrl = item.url.split('.com/')[1];
          if (item.url.includes('.mp4')) {
            item.fileType = 'mp4';
          } else {
            item.fileType = 'mp3';
          }
        })
        console.log(this.transcribeList);
      }
    },
    // 删除议题
    async delTopic(item) {
      let res = await deleteDiscussion(item.id);
      console.log(res);
      if (res.code == 0) {

      }
    },
    // 下载录制文件
    // download(item,index) {
    //   // console.log(item);return
    //   let client = new OSS({
    //     region: 'oss-cn-shanghai',
    //     accessKeyId: this.ossInfo.accessid,
    //     accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
    //     bucket: 'ilaas-files',
    //   })
    //   let filename = `${item.fileType}_${index}`;
    //   let response = {
		//   	"content-disposition": `attachment; filename=${encodeURIComponent(filename)};expires: 7200`
    //   }
    //   let url = client.signatureUrl(item.dwUrl, {
		// 		response,
		// 	});
		// 	window.location.href = url;
    // },
    async download(item,index) {
      let params = {
        expireTime: 3600,
        objectName: item.dwUrl,
        BucketName: 'ilaasroom-file',
        EndPoint: 'https://oss-cn-shanghai.aliyuncs.com',
      };
      let ress = await getFileUrl(params);
      fetch(ress).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        document.body.appendChild(a)
        a.style.display = 'none'
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // 指定下载的文件名
        a.download = `${item.fileType}_${index+1}.${item.fileType}`;
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    },
    async download2(item) {
      let params = {
        expireTime: 3600,
        objectName: item.filePath,
        BucketName: 'ilaas-files',
      };
      let ress = await getFileUrl(params);
      fetch(ress).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        document.body.appendChild(a)
        a.style.display = 'none'
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // 指定下载的文件名
        a.download = item.fileName;
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    },
    // 保存
    async edit() {
      let newArr = [];
      this.discussionList.map(item => {
        newArr.push(
          {
            title: item.title,
            content: item.content,
            summary: item.summary,
            id: item.id,
          }
        )
      })
      let res = await editDiscussion({items: newArr});
      // console.log(res);
      if (res.code == 0) {
        this.$parent.detailsStatus = false;
      }
    },
    // 返回
    goBack() {
      this.$parent.detailsStatus = false;
    },
  },
}
</script>

<style lang="less" scoped>
  .conference-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 18px;
    padding: 20px;
    // .all-crumbs {
    //   margin-bottom: 10px !important;
    // }
    .btns-box {
      display: flex;
      justify-content: space-between;
      .back-btn {
        width: 80px;
        height: 30px;
        border-radius: 15px;
        color: #6474C7;
        background-color: #fff;
        border: 1px solid #6474C7;
        margin-bottom: 10px;
      }
    }
    .center-box{
      height: 85%;
      display: flex;
      justify-content: space-between;
      .left-side {
      display: flex;
      flex-direction: column;
      width: 18%;
      height: 100%;
      border-radius: 18px;
      border: 1px solid #dee2e6;
      padding: 16px;
      box-sizing: border-box;
      .title {
        font-weight: 600;
        color: #333;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee2e6;
      }
      .left-cards-box {
        flex: 1;
        overflow-y: auto;
        padding-top: 14px;
        p {
          color: #202124;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 60%;
      // height: 100%;
      /deep/ .center-cards-box {
        // flex: 1;
        // height: 90% !important;
        height: 100%;
        overflow-y: auto;
        .card {
          min-height: 200px;
          border-radius: 18px;
          border: 1px solid #dee2e6;
          padding: 16px;
          box-sizing: border-box;
          margin-bottom: 15px;
          .head-box {
            p {
              display: flex;
              align-items: center;
              font-weight: 600;
              color: #333;
              .el-input {
                border: none;
                .el-input__inner {
                  border: none;
                }
              }
              .del-icon {
                width: 18px;
                cursor:pointer;
              }
            }
          }
          .centent-box {
            display: flex;
            margin-top: 16px;
            padding: 0 0 10px 20px;
            border-bottom: 1px solid #dee2e6;
            .label {
              color: #dee2e6;
              white-space: nowrap;
            }
            // .val-box {
            //   color: #333;
            // }
            .el-textarea {
              .el-textarea__inner {
                padding: 0;
                border: none;
              }
            }
          }
          .foot {
            display: flex;
            justify-content: flex-end;
            margin-top: 14px;
            p {
              display: flex;
              align-items: center;
              min-width: 80px;
              height: 30px;
              border-radius: 15px;
              padding: 0 10px;
              color: #909399;
              border: 1px solid #8279f3;
              span {
                color: #8279f3;
                max-width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6%;
        background: #f8f9fa;
        border-radius: 18px;
        border: 1px solid #dee2e6;
        margin-top: 10px;
        span {
          color: #909399;
          margin-left: 10px;
        }
      }
    }
    .right-box {
      width: 18%;
      height: 100%;
      // border-radius: 18px;
      // border: 1px solid #dee2e6;
      // padding: 16px;
      // box-sizing: border-box;
      .box-1 {
        min-height: 20%;
        border-radius: 18px;
        border: 1px solid #dee2e6;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 12px;
        .title {
          font-weight: 600;
          color: #202124;
          padding-bottom: 10px;
          border-bottom: 1px solid #ccc;
        }
        .row-box {
          display: flex;
          align-items: center;
          p {
            color: #202124;
          }
        }
        .row-box2 {
          max-height: 100px;
          overflow-y: auto;
          span {
            font-size: 12px;
            color: #909399;
          }
        }
      }
      .box-2 {
        min-height: 35%;
        .file-box {
          max-height: 180px;
          overflow-y: auto;
          .card {
            display: flex;
            align-items: center;
            flex-direction: initial;
            margin-top: 4px;
            img {
              width: 100%;
              height: 100%;
              margin-right: 4px;
            }
            .img1 {
              width: 20%;
            }
            .right-box {
              width: 60%;
              .file-name {
                width: 100%;
                font-size: 14px;
                color: #202124;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .date {
                font-size: 12px;
                color: #909399;
              }
            }
              .btn-p {
                color: #8279F3;
                cursor:pointer;
              }
          }
        }
      }
      .box-3 {
        min-height: 35%;
        .file-box2 {
          height: 70%;
          overflow-y: auto;
          .card2 {
            display: flex;
            align-items: center;
            margin-top: 6px;
            border: 1px solid #ccc;
            padding: 6px;
            border-radius: 4px;
            img {
              width: 32px;
              height: 36px;
            }
            .right-box {
              width: 70%;
              margin-left: 6px;
              .name {
                width: 100%;
                font-size: 12px;
                color: #606266;
                white-space:nowrap;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .btn-p {
                font-size: 12px;
                color: #6474C7;
                margin-top: 2px;
                cursor:pointer;
              }
            }
          }
        }
      }
    }
    }
  }
</style>

