<template>
  <div class="add-conference">
    <el-dialog
      :title="info.type == 'add' ? '新建会议' : '修改会议'"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="460px"
      :before-close="handleClose"
    >
      <div class="row-box">
        <p class="label">
          <span>* </span>
          会议标题
        </p>
        <el-input placeholder="请输入会议标题" v-model="form.name"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          选择日期
        </p>
        <el-date-picker
          v-model="dateVal"
          :clearable="false"
          :picker-options="$set_PickerOption1()"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          选择时间
        </p>
        <div class="time-box">
          <el-time-select
            placeholder="开始时间"
            v-model="startTime"
            :clearable="false"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30',
              maxTime: endTime,
            }"
          >
          </el-time-select>
          <el-time-select
            placeholder="结束时间"
            v-model="endTime"
            :clearable="false"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30',
              minTime: startTime,
            }"
          >
          </el-time-select>
        </div>
      </div>
      <div class="row-box">
        <p class="label">会议类型</p>
        <div class="type-box">{{form.typeVal}}</div>
        <!-- <el-select v-model="form.typeVal" filterable placeholder="请选择会议类型">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          会议目标
        </p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 6 }"
          placeholder="请输入会议目标..."
          v-model="form.target"
        >
        </el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addConference } from '@/FackApi/api/iLaaSRoom.js';
export default {
  name: "addConference",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        name: "",
        startTime: "",
        endTime: "",
        typeVal: '普通会议',
        target: '',
      },
      dateVal: "",
      startTime: "",
      endTime: "",
      typeList: [
        {label: '普通会议', label: '普通会议'},
      ],
      throttleNum: 0,
      flag: null, // 计时器
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      if (this.info.type == "edit") {
        this.form = {
          name: this.info.item.name,
          phone: this.info.item.mobile,
          job: this.info.item.position,
          id: this.info.item.id,
        };
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          // done();
          this.$parent.showDialog = false;
        })
        .catch((_) => {});
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(()=>{
        that.throttleNum ++;
        console.log(that.throttleNum);
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      },1000)
      if (!this.form.name) {
        this.$message.warning(`会议标题不能为空！`);
        return;
      }
      if (!this.dateVal) {
        this.$message.warning(`会议日期不能为空！`);
        return;
      }
      if (!this.startTime || !this.endTime) {
        this.$message.warning(`请选择开始结束时间！`);
        return;
      }
      if (!this.form.target) {
        this.$message.warning(`会议目标不能为空！`);
        return;
      }
      // console.log('请求');return
      let date = this.$getDate(1,this.dateVal);
      // console.log(date);
      let params = {
        name: this.form.name,
        startTime: `${date} ${this.startTime}:00`,
        endTime: `${date} ${this.endTime}:00`,
        content: this.form.target,
        type: 1,
      };
      // console.log(params);return
      let res = await addConference(params);
      if (res.code == 0) {
        this.$parent.init();
        this.cancel();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.flag);
  },
};
</script>

<style lang="less" scoped>
.add-conference {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 20px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #909399;
            margin-right: 18px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 6px;
              border: 1px solid #d7dbda;
            }
          }
          .el-date-editor {
            width: 100%;
            .el-input__prefix {
              i {
                font-size: 20px;
                margin-top: 2px;
              }
            }
          }
          .time-box {
            display: flex;
            justify-content: space-between;
            .el-date-editor {
              width: 48%;
              .el-input__inner {
                width: 100%;
              }
              .el-input__prefix {
                i {
                  font-size: 20px;
                  margin-top: 2px;
                }
              }
            }
          }
          .el-textarea {
            .el-textarea__inner {
              border-radius: 6px;
            }
          }
          .type-box {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border-radius: 6px;
            padding-left: 10px;
            color: #333;
            border: 1px solid #d7dbda;
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 140px;
            height: 40px;
            font-size: 14px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            cursor: pointer;
          }
          .btn2 {
            flex: 1;
            margin-left: 20px;
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
