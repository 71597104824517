// 引入request
import requests from '../../Utils/request'

//添加会议
export const addConference = (data) => requests({
  url: `/api/conference/add`,
  method: 'post',
  data
})
//获取会议列表
export const getConference = (params) => requests({
  url: `/api/conference/paging/${params}`,
  method: 'get',
})
//获取历史会议列表
export const getHistoryConference = (params) => requests({
  url: `/api/conference/history/paging/${params}`,
  method: 'get',
})
//确认完成审查
export const confirmExamine = (id) => requests({
  url: `lawyerConsult/Confirm/${id}`,
  method: 'post',
  // data
})
//添加录制模板
export const roomInitRecord = (data) => requests({
  url: `api/conference/room/InitRecord`,
  method: 'post',
  data
})
//开始录制
export const StartRecord = (data) => requests({
  url: `api/conference/room/StartRecord`,
  method: 'post',
  data
})
//结束录制
export const StopRecord = (id) => requests({
  url: `api/conference/room/StopRecord/${id}`,
  method: 'post',
  // data
})
//开始会议
export const StartConference = (num) => requests({
  url: `api/conference/start/${num}`,
  method: 'post',
  // data
})
//结束会议
export const EndConference = (num) => requests({
  url: `api/conference/Finish/${num}`,
  method: 'post',
  // data
})
// 会议详情
export const getHistoryDetails = (num) => requests({
  url: `api/conference/history/${num}`,
  method: 'get',
  // data
})
// 会议详情修改议题
export const editDiscussion = (data) => requests({
  url: `api/conference/issue/multiple/edit`,
  method: 'post',
  data
})
// 会议详情删除议题
export const deleteDiscussion = (num) => requests({
  url: `api/conference/discussion/delete/${num}`,
  method: 'delete',
  // data
})
// 获取会议详情
export const getConferenceDetail = (num) => requests({
  url: `api/conference/detail/${num}`,
  method: 'get',
  // data
})
// 导出会议纪要
export const getIssueExport = (id) => requests({
  url: `api/conference/issue/export/${id}`,
  method: 'get',
  responseType: 'blob',
  // data
})
// 删除会议
export const conference = (id) => requests({
  url: `api/conference/delete/${id}`,
  method: 'delete',
  // data
})
// 文件协同文件上传
export const uploadConferenceFile = (data) => requests({
  url: `api/conferencefile/upload`,
  method: 'post',
  data
})
