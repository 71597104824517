<template>
  <div class="set-room-link">
    <el-dialog
      title="iLaaS Room 链接地址"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="460px"
      :before-close="handleClose"
    >
      <p @click="linkUrl" class="link-p">{{info.path}}</p>
      <div class="btns-box">
        <button class="btn" @click="cancel">关 闭</button>
        <button class="btn2" @click="linkUrl">复 制</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "setRoomLink",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      myInfo: null,
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          // done();
          this.$parent.roomLinDialog = false;
        })
        .catch((_) => {});
    },
    cancel() {
      this.$parent.roomLinDialog = false;
    },
    // 复制
    linkUrl() {
      // this.info.path
      let url = this.info.path;
      let copyInput = document.createElement('input');//创建input元素
      document.body.appendChild(copyInput);//向页面底部追加输入框
      copyInput.setAttribute('value', url);//添加属性，将url赋值给input元素的value属性
      copyInput.select();//选择input元素
      document.execCommand("Copy");//执行复制命令
      this.$message.success("链接已复制！");//弹出提示信息，不同组件可能存在写法不同
      //复制之后再删除元素，否则无法成功赋值
      copyInput.remove();//删除动态创建的节点
    },
  },
};
</script>

<style lang="less" scoped>
.set-room-link {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            white-space: nowrap;
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .link-p {
          cursor: pointer;
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
